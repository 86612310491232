import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { User, MutOptions } from "../types"
import { UserFragmentFragmentDoc as UserFragment } from '../generated'

export const CHANGE_PASSWORD = gql`
	mutation changePassword($email: String, $oldPassword: String!, $newPassword: String!) {
		changePassword(email: $email, oldPassword: $oldPassword, newPassword: $newPassword) {
			user { ...UserFragment }
			token
		}
	}
	${UserFragment}
`;

interface Args {
    email?: string
    oldPassword: string
    newPassword: string
}

export interface ChangeResult {
    changePassword: {
        user: User
        token: string
    }
}

// If no options are specified, these defaults are used.
const baseOptions: MutOptions<ChangeResult, Args> = {
}
export function useChangePassword(options: MutOptions<ChangeResult, Args> = {}) {
    return useMutation<ChangeResult, Args>(CHANGE_PASSWORD, {
        ...baseOptions,
        ...options,
        variables: { ...baseOptions.variables, ...options.variables }
    });
}

import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { Bid, ContractorJob, MutOptions } from '../types'
import { GET_CONTRACTOR_JOBS, GET_USER } from '../queries'
import { ContractorJobFragmentFragmentDoc as ContractorJobFragment } from '../generated'

export const CREATE_BID = gql`
	mutation createBid($jobId: ID!, $amount: Float!) {
		createBid(jobId: $jobId, amount: $amount) {
			...ContractorBidFragment
			job {
				...ContractorJobFragment
			}
		}
	}
	${ContractorJobFragment}
`

interface Args {
	jobId: string
	amount: number
}
interface SaveResult {
	createBid: Readonly<Bid> & {
		job: Partial<ContractorJob>
	}
}

// If no options are specified, these defaults are used.
export const baseOptions: MutOptions<SaveResult, Args> = {
	refetchQueries: [{ query: GET_CONTRACTOR_JOBS }, { query: GET_USER }]
}
export function useCreateBid(options: MutOptions<SaveResult, Args> = {}) {
	return useMutation<SaveResult, Args>(CREATE_BID, {
		...baseOptions,
		...options,
		variables: { ...baseOptions.variables, ...options.variables }
	})
}

import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { Contractor, MutOptions } from "../types"
import { ContractorFragmentFragmentDoc as ContractorFragment } from '../generated'

export const SET_CONTRACTOR_COUNTIES = gql`
	mutation setContractorCounties($counties: [ID!]!) {
		setContractorCounties(counties: $counties) {
			...ContractorFragment
		}
	}
  ${ContractorFragment}
`;

export interface Args {
  counties: string[]
}

export interface ChangeResult {
  setContractorCounties: Contractor
}

// If no options are specified, these defaults are used.
const baseOptions: MutOptions<ChangeResult, Args> = {
}
export function useSetContractorCounties(options: MutOptions<ChangeResult, Args> = {}) {
    return useMutation<ChangeResult, Args>(SET_CONTRACTOR_COUNTIES, {
        ...baseOptions,
        ...options,
        variables: { ...baseOptions.variables, ...options.variables }
    });
}

import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { PaymentMethod, UpdateArgs, MutOptions } from '../types'
import { GET_PAYMENT_METHOD, GET_CHARGES } from '../queries'

export const SAVE_PAYMENT_METHOD = gql`
    mutation SavePaymentMethod(
        $id: ID
        $redactedCard: String!
        $expMonth: Int!
        $expYear: Int!
        $stripeSourceId: String
        $stripePaymentMethodId: String
        $fullName: String!
        $addressLine1: String!
        $addressLine2: String
        $city: String!
        $state: String!
        $zipCode: String!
        $isDefault: Boolean
    ) {
        savePaymentMethod(
            paymentMethodId: $id
            redactedCard: $redactedCard
            expMonth: $expMonth
            expYear: $expYear
            stripeSourceId: $stripeSourceId
            stripePaymentMethodId: $stripePaymentMethodId
            fullName: $fullName
            addressLine1: $addressLine1
            addressLine2: $addressLine2
            city: $city
            state: $state
            zipCode: $zipCode
            isDefault: $isDefault
        ){
            id
            redactedCard
            expMonth
            expYear
            fullName
            addressLine1
            addressLine2
            city
            state
            zipCode
            isDefault
        }
    }
`;

type Args = UpdateArgs<Partial<PaymentMethod>>
export interface SaveResult { savePaymentMethod: Readonly<PaymentMethod> }

// If no options are specified, these defaults are used.
const baseOptions: MutOptions<SaveResult, Args> = {
    refetchQueries: [{ query: GET_PAYMENT_METHOD }, { query: GET_CHARGES }],
    variables: { isDefault: true }
}
export function useSavePaymentMethod(options: MutOptions<SaveResult, Args> = {}) {
    return useMutation<SaveResult, Args>(SAVE_PAYMENT_METHOD, {
        ...baseOptions,
        ...options,
        variables: { ...baseOptions.variables, ...options.variables }
    });
}

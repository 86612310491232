import gql from 'graphql-tag';
import {
	UserFragmentFragmentDoc as UserFragment,
	ContractorFragmentFragmentDoc as ContractorFragment,
	HomeownerJobFragmentFragmentDoc as HomeownerJobFragment,
	ContractorJobFragmentFragmentDoc as ContractorJobFragment,
} from '../generated'

export { CHANGE_PASSWORD, useChangePassword } from './changePassword'
export type { ChangeResult as ChangePasswordResult } from './changePassword'
export { SAVE_PAYMENT_METHOD, useSavePaymentMethod } from './savePaymentMethod'
export { CREATE_BID, useCreateBid } from './createBid'
export { SET_CONTRACTOR_COUNTIES, useSetContractorCounties } from './setContractorCounties'

export const UPDATE_SELECTED_PROPERTY_ID = gql`
    mutation updateSelectedPropertyId($id: String) {
        updateSelectedPropertyId(id: $id) @client
    }
`;

export const CREATE_JOB = gql`
    mutation CreateJob(
        $pictures: [String!],
        $selections: [String!]!
        $date: String!
        $description: String!
        $propertyId: ID!
        $trades: [ID!]
        $propertyMeasurements: Json
			  $subOptionId: ID!
    ) {
        createJob(
            date: $date
            description: $description
            pictures: $pictures
            selections: $selections
            propertyId: $propertyId
            trades: $trades
					  propertyMeasurements: $propertyMeasurements
					  subOptionId: $subOptionId
        ){
            ...HomeownerJobFragment
        }
    }
	${HomeownerJobFragment}
`;

export const CREATE_PROPERTY = gql`
	mutation CreateProperty(
		$houseNumber: String!
		$streetName: String!
		$city: String!
		$county: ID!
		$state: String!
		$zipCode: String!
		$lat: String!
		$lng: String!
		$intendedUserId: ID
	) {
		createProperty(
			houseNumber: $houseNumber
			streetName: $streetName
			city: $city
			county: $county
			state: $state
			zipCode: $zipCode
			lat: $lat
			lng: $lng
			intendedUserId: $intendedUserId
		) {
			id
			houseNumber
			streetName
			city
			state
			county {
				id
			}
			zipCode
			ready
		}
	}
`;

export const HIRE_BID = gql`
	mutation hireBid($jobId: ID!, $bidId: ID!) {
		hireBid(jobId: $jobId, bidId: $bidId) {
			...HomeownerJobFragment
		}
	}
	${HomeownerJobFragment}
`;

export const REJECT_BID = gql`
	mutation rejectBid($jobId: ID!, $bidId: ID!) {
		rejectBid(jobId: $jobId, bidId: $bidId) {
			...HomeownerJobFragment
		}
	}
	${HomeownerJobFragment}
`;

export const UPDATE_CONTRACTOR_TRADES = gql`
	mutation($trades: [ID!]!) {
		updateContractorTrades(trades: $trades) {
			id,
			trades { id, group }
		}
	}
`;

export const COMPLETE_JOB = gql`
	mutation completeJobContractor($jobId: ID!) {
		completeJobContractor(jobId: $jobId) {
			...ContractorJobFragment
		}
	}
	${ContractorJobFragment}
`;

export const COMPLETE_JOB_HOMEOWNER = gql`
	mutation completeJob($jobId: ID!) {
		completeJob(jobId: $jobId) {
			...HomeownerJobFragment
		}
	}
	${HomeownerJobFragment}
`;

export const REOPEN_JOB_HOMEOWNER = gql`
	mutation reopenJob($jobId: ID!) {
		reopenJob(jobId: $jobId) {
			...HomeownerJobFragment
		}
	}
	${HomeownerJobFragment}
`;

export const SIGN_UP_CONTRACTOR = gql`
	mutation SignUpContractor(
		$companyName: String!
		$companyAdminName: String!
		$companyPosition: String
		$email: String!
		$password: String!
		$trades: [ID!]
		$counties: [ID!]
		$skipEmail: Boolean
		$jobId: ID
		$source: String!
	) {
		signupContractor(
			companyName: $companyName
			companyAdminName: $companyAdminName
			companyPosition: $companyPosition
			email: $email
			password: $password
			trades: $trades
			counties: $counties
			skipEmail: $skipEmail
			jobId: $jobId
			source: $source
		) {
			token
		}
	}
`

export const SIGN_UP_USER = gql`
	mutation SignUp(
		$firstName: String!
		$lastName: String!
		$email: String!
		$password: String!
		$skipEmail: Boolean
		$purpose: HomeownerPurpose
		$source: String!
	) {
		signup(
			firstName: $firstName
			lastName: $lastName
			email: $email
			password: $password
			skipEmail: $skipEmail
			purpose: $purpose
			source: $source
		) {
			user { ...UserFragment }
			token
		}
	}
	${UserFragment}
`

export const LOGIN_USER = gql`
	mutation LoginUser($email: String, $password: String) {
		login(email: $email, password: $password) {
			user { ...UserFragment }
			token
		}
	}
	${UserFragment}
`

export const LOGIN_USER_WITH_KEY = gql`
	mutation LoginUser($id: String, $oneTimeKey: String) {
		login(id: $id, oneTimeKey: $oneTimeKey) {
			user { ...UserFragment }
			token
		}
	}
	${UserFragment}
`

export const SET_CONTRACTOR_AVATAR = gql`
	mutation SetContractorAvatar($url: String) {
		setContractorAvatar(url: $url) {
			...ContractorFragment
		}
	}
	${ContractorFragment}
`

export const SET_CONTRACTOR_PHOTOS = gql`
	mutation SetContractorPictures($urlList: [String!]!) {
		setContractorPictures(urlList: $urlList) {
			id
		}
	}
`

export const DELETE_JOB_PHOTO_HOMEOWNER = gql`
	mutation DeleteJobPhoto($photoId: ID!, $jobId: ID!) {
		deleteJobPhoto(photoId: $photoId, jobId: $jobId) {
			...HomeownerJobFragment
		}
	}
	${HomeownerJobFragment}
`

export const ADD_JOB_PHOTOS_HOMEOWNER = gql`
	mutation AddJobPhotos($urls: [String!]!, $jobId: ID!) {
		addJobPhotos(urls: $urls, jobId: $jobId) {
			...HomeownerJobFragment
		}
	}
	${HomeownerJobFragment}
`
export const SET_HOMEOWNER_FIELD = gql`
	mutation SetHomeownerField(
		$firstName: String,
		$lastName: String,
	) {
		setHomeOwnerField(
			firstName: $firstName,
			lastName:  $lastName,
		) {
			firstName
			lastName
		}
	}
`;

export const SET_CONTRACTOR_FIELD = gql`
	mutation SetContractorAvatar(
		$companyName: String,
		$primaryWebsite: String,
		$companyAddress: String,
		$companyAdminName: String,
		$companyPosition: String,
		$secondaryWebsite: String,
		$companyPhone: String,
		$companyBio: String,
	) {
		setContractorField(
			companyName: $companyName,
			primaryWebsite: $primaryWebsite,
			companyAddress: $companyAddress,
			companyAdminName: $companyAdminName,
			companyPosition: $companyPosition,
			secondaryWebsite: $secondaryWebsite,
			companyPhone: $companyPhone,
			companyBio: $companyBio,
		) {
			...ContractorFragment
		}
	}
	${ContractorFragment}
`;

export const SET_NOTIFICATION_AS_READ = gql`
	mutation MarkNotificationAsRead (
	$id: ID!
	) {
		markNotificationAsRead(
			id: $id
		) {
			id
		}
	}
`;
